import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import "./index.css"

export default function ReferenzenPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Referenzen"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Referenzen"
        lang="de"
      />
      <Header />
      <section>
        <div className="paddingReferenzenDiv">
          <h1 className="referenzenH1">Referenzen</h1>
          <div className="referenzenDiv">
            <div className="wandReferenzen">
              <h2 className="referenzenH2">Wand</h2>
              <div className="refDiv">
                <div className="jahreszahlDiv">2019</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Beuren bei Isny, kath. Pfarrkirche St. Petrus
                  </h3>
                  <p className="referenzP">
                    Restaurierung der Wandmalereien von 1929: Reinigung und
                    Behandlung gegen Mikroorganismen, Hinterfüllung, Risskittung
                    und Strichretusche, Betreuung: Janine Butenuth (M.A.),
                    Denkmalpflege Baden-Württemberg, Regierungspräsidium
                    Tübingen und Dipl.-Ing. Hartmut Junker (Büro Junker +
                    Partner) und Dipl.-Ing. Ulrich Volkmann. Beuren in
                    Zusammenarbeit mit Stuckrestaurator S. Kljacijc (VDR).
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2018</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Schloss Schwetzingen, Zweiflügelanlage genannt Perspektiv
                  </h3>
                  <p className="referenzP">
                    (1776–1778) erbaut von Nicolas de Pigage: Wandmalerei um
                    1760 in den seitlichen Bogenfeldern des Atriums:
                    Konsolidierung von Putz und Farbfassung, Hinterfüllung von
                    Hohlstellen und Putzergänzung, Strichretusche von
                    Fehlbereichen innerhalb der Malerei und der
                    Architekturfassung. Maßnahmenkartierung im CAD,
                    Kostenschätzung für die gesamte Architekturmalerei der
                    Zweiflügelanlage. Betreuung: Dipl. Ing. Guido Jordine. Im
                    Auftrag der Staatliche Schlösser und Gärten
                    Baden-Württemberg.
                  </p>
                </div>
              </div>
              {/*               <div className="refDiv">
                <div className="jahreszahlDiv">2015 – 2019</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Masterstudium und Masterthesis</h3>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2013 – 2015</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Bachelorstudium und Bachelorthesis
                  </h3>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2012</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Lehrtätigkeit und Vorbereitung auf das Studium
                  </h3>
                </div>
              </div> */}
              <div className="refDiv">
                <div className="jahreszahlDiv">2008 – 2009</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    St. Leonhard, Regensburg, ehem. Johanniterkommende aus dem
                    13. Jh
                  </h3>
                  <p className="referenzP">
                    Untersuchung der Raumschale, Erstellung einer Dokumentation
                    mit Erfassung der baulichen Veränderungen. Neufassung der
                    Raumschale mit Kalklasuren. Im Auftrag der kath.
                    Kirchenverwaltung St. Leonhard.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2008</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Pfarrkirche St. Petrus in Sandsbach
                  </h3>
                  <p className="referenzP">
                    Befund und Rekonstruktion der Raumschalenfassung, von Martin
                    Bader (1730–1750). Im Auftrag der kath. Kirchenverwaltung
                    Sandsbach.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2008 – 2009</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Pfarrkirche Mariä Himmelfahrt in Beidl (erbaut 1729–1732)
                    Chorraum
                  </h3>
                  <p className="referenzP">
                    Raumschale und Deckengemälde von 1890, Hinterfüllung
                    hohlliegender Stellen und Risskittung. Reduzierung von
                    Weißschleierbildungen. Strichretuschen an den Schadstellen.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2006</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Kath. Stadtpfarrkirche Hl. Dreifaltigkeit, Amberg
                  </h3>
                  <p className="referenzP">
                    Restaurierung des Freskenzyklus von Ludwig Angerer von 1936.
                    Wiederherstellung der Raumfassung nach Befund. Im Auftrag
                    der kath. Kirchenverwaltung Amberg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2004 – 2005</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Burg Schwaneck, München–Pullach
                  </h3>
                  <p className="referenzP">
                    Ritterburg aus dem Jahre 1843 von Ludwig Schwanthaler nach
                    dem Entwurf des Architekten Friedrich von Gärtner:
                    Restaurierung historischer Wandmalereien aus dem 19. Jh.
                    Rekonstruktion der Quadermalerei im Rittersaal und Retusche
                    des Sternenhimmels. Im Auftrag der Stadt München.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2003 – 2004</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Wallfahrtskirche "Zur Freudenreichen Dreifaltigkeit“
                    Gößweinstein
                  </h3>
                  <p className="referenzP">
                    1730–39 erbaut von Balthasar Neumann und Johann Jakob
                    Michael Küchel mit Fresken von 1928 im Stil des Barocks von
                    Waldemar Kolmsperger und seinem gleichnamigen Sohn:
                    Arbeitsproben, Freskenreinigung, Retusche und
                    Salzreduzierung Dokumentation der Tagewerksgrenzen und
                    durchgeführten Maßnahmen. Im Auftrag der Erzdiözese Bamberg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2003 – 2004</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Rathaus Sulzbach Rosenberg</h3>
                  <p className="referenzP">
                    Verkündungserker, Freilegung, Putzergänzung, Rekonstruktion
                    der historischen Quadermalerei in Kalktechnik. Retusche des
                    historischen "Richterspruchs". Im Auftrag der Stadt Sulzbach
                    Rosenberg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2002</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    St. Michael, Burgfelden bei Bahlingen
                  </h3>
                  <p className="referenzP">
                    Putzergänzung an romanischen Wandmalereien des 11.
                    Jahrhunderts mit farblich und strukturell passenden Sanden
                    analog einer Retusche. Im Auftrag von Restaurator Peter
                    Volkmer.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2002</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Rathaus, Sulzbach Rosenberg</h3>
                  <p className="referenzP">
                    Sandsteinquaderung der Fassade, die mit rosafarbenen
                    Kalklasuren gestaltet waren. Die Oberflächen trugen eine
                    Putzschicht mit aufgesetzter Fugenbänderung und rotem
                    Fugenstrich. Untersuchung und Arbeitsmuster für die
                    Ausführung der Fassadengestaltung. Im Auftrag der Stadt
                    Sulzbach – Rosenberg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2000</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Mariae Himmelfahrt, ehemalige Benediktinerabteikirche,
                    Frauenzell, Grundsteinlegung 1737
                  </h3>
                  <p className="referenzP">
                    Stuckaturen von Anton Landes. Deckengemälde von den
                    Asamschülern Andreas und Otto Gebhard, um 1750. Behandlung
                    der Mikroorganismen, Risskittung und Retusche. Risskittung
                    und Retusche statisch bedingter Risse. Im Auftrag des
                    staatlichen Bauamtes Regensburg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1999</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Bamberg, Altes Rathaus, Malerei von Johann Anwander
                  </h3>
                  <p className="referenzP">
                    Retusche der Fassadenmalerei. Im Auftrag von der
                    Bieter-Gemeinschaft Marlis Hirsekorn und Erwin Rösch.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1999</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Kath. Filialkirche St. Laurenzius, Eschenhart
                  </h3>
                  <p className="referenzP">
                    Untersuchung der originalen Raumschalenbemalung des
                    Asamschülers Martin Bader (1740–1760). Im Auftrag der kath.
                    Kirchenverwaltung Eschenhart.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1999</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Bamberg, Alte Hofhaltung, ehemaliges Fürstenzimmer
                  </h3>
                  <p className="referenzP">
                    Wanddekorationen von 1640 im Heinrich Zimmer. Strichretusche
                    an den Grisaillemalereien. Im Auftrag der Bayerischen
                    Schlösser und Seenverwaltung in Zusammenarbeit mit Erwin
                    Rösch.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1997 – 1998</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Oberleierndorf, St. Stephanus, Filialkirche des Klosters
                    Parings
                  </h3>
                  <p className="referenzP">
                    Restaurierung der Raumschale mit Stuckdekorationen des
                    Asamschülers Martin Bader (1740–1760), Untersuchung und
                    Rekonstruktion der verlorengegangenen Brokatmalerei am
                    Stuckvorhang. Im Auftrag der kath. Kirchenverwaltung
                    Oberleierndorf.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1997</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Mariendom in Freising, Stuckdekorationen von Egid Quirin
                    Asam (1722–1724)
                  </h3>
                  <p className="referenzP">
                    Erstellung einer Musterachse im Bereich Raumschale.
                    Arbeitsgemeinschaft von Restaurator Herbert Zernikel.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1996</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Restaurierung der Wandmalereien von J. I. Appiani
                    (1759–1762)
                  </h3>
                  <p className="referenzP">
                    am Plafond des Treppenhauses im Neuen Schloss in Meersburg,
                    Ausführung von aufwändigen Retuschen in Tratteggiotechnik.
                    Im Auftrag von Restaurator Peter Volkmer.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1994 / 1996</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Ehemalige Zisterzienserabtei Ebrach, Kath. Pfarrkirche St.
                    Maria Johannes und Nikolaus
                  </h3>
                  <p className="referenzP">
                    Musterrestaurierung an der klassizistischen
                    Stuckdekorationen von 1774/1784 in der Raumschale und am
                    Hochaltar von Materno Bossi (1737–1802) Konservierung
                    pudernder Malschicht und Vergoldung, Arbeitproben und
                    Beschreibung der Maßnahmen für die nachfolgende
                    Ausschreibung. Arbeitsgemeinschaft unter Leitung von
                    Eberhard Reichelt.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1988 – 1990</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Wieskirche, genannt Wallfahrtskirche zum Gegeißelten Heiland
                    auf der Wies, UNESCO-Weltkulturerbe von Johann Baptist und
                    Dominikus Zimmermann (1745–1754)
                  </h3>
                  <p className="referenzP">
                    Konservierung originaler Vergoldung an den Kapitellen im
                    Zentralraum. Rekonstruktion verlorengegangener Vergoldung
                    nach originalem Vorbild am Deckenbildrahmen. Konservierung,
                    Fehlstellenkittung und Blattgoldergänzung an den
                    Seligkeitskartuschen im Zentralraum. Retusche der
                    Brokatmalereien in Strichtechnik mit Pudergold im
                    Zentralraum. Arbeitsgemeinschaft unter Leitung von Klaus
                    Klarner.
                  </p>
                </div>
              </div>
            </div>
            <div className="wandReferenzen">
              <h2 className="referenzenH2">Ausstattung</h2>
              <div className="refDiv">
                <div className="jahreszahlDiv">2019</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Kath. Pfarrkirche St. Petrus und Paulus, Beuren bei Isny
                  </h3>
                  <p className="referenzP">
                    Restaurierung der beweglichen Ausstattung, Kanzel, Altäre
                    darunter Tafelmalereien der gotischen Flügelaltäre, die Hans
                    Schäufelein um 1507 schuf. Maßnahmen: Konservierung mit
                    Hausenblase, strukturelle Holzfestigung, Reinigung, Kittung
                    und Retusche. Betreuung: Dipl.-Ing. Ulrich Volkmann und
                    Dipl.-Ing. Hartmut Junker (Büro Junker + Partner) sowie
                    Restaurator Jochen Ansel, Landesamt für Denkmalpflege
                    Baden-Württemberg, Esslingen a. Neckar. Auftraggeber Kath.
                    Kirchengemeinde Beuren.
                  </p>
                </div>
              </div>
              {/* <div className="refDiv">
                <div className="jahreszahlDiv">2017</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Eremitage, Bayreuth: Restaurierung diverser Sitzmöbel
                  </h3>
                  <p className="referenzP">
                    aus dem Rokoko und Historismus. Konservierung der
                    Vergoldung, Retusche mit Poliment, Pudergold und Blattgold.
                    Restaurierung der Weißfassung. Bayerische Verwaltung der
                    staatlichen Schlösser, Gärten und Seen. Betreuung
                    Restaurator Bernhard Mintrop. Im Auftrag und Zusammenarbeit
                    mit Holzrestaurator Robert Zachmayer.
                  </p>
                </div>
              </div> */}
              {/* <div className="refDiv">
                <div className="jahreszahlDiv">2015 – 2019</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Masterstudium und Masterthesis</h3>
                </div>
              </div> */}
              <div className="refDiv">
                <div className="jahreszahlDiv">2015 – 2016</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Gärten der Welt, Berlin-Marzahn
                  </h3>
                  <p className="referenzP">
                    Restaurierung des orientalischen Pavillons im Garten der
                    vier Ströme: Die ornamentale Malerei wurde ursprünglich in
                    der sogenannten Zouak Technik ausgeführt. Abnahme
                    austretender Harze. Retusche und Teilrekonstruktion der
                    zerstörten Arabeskenmalerei. Betreuung: Grün Berlin GmbH
                    Dipl. – Ing. Hergen Stolle und Parkmanagerin Frau Beate
                    Reuber, Schirmherr Berliner Senat.
                  </p>
                </div>
              </div>
              {/* <div className="refDiv">
                <div className="jahreszahlDiv">2013 – 2015</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Bachelorstudium und Bachelorthesis
                  </h3>
                </div>
              </div> */}
              <div className="refDiv">
                <div className="jahreszahlDiv">2009</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Pfarrkirche Maria Himmelfahrt in Beidl
                  </h3>
                  <p className="referenzP">
                    Restaurierung des Hochaltars und der Kanzel mit Skulpturen
                    und Leinwandgemälde. Holzkonservierung, Reinigung, Festigung
                    und Retusche der Farbfassung und Vergoldung. Im Auftrag der
                    kath. Kirchenverwaltung Beidl.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2009</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Mitarbeit bei Prof. Ana Pecoraro in São Paulo, Brasilien
                  </h3>
                  <p className="referenzP">
                    Restaurierung eines venezianischen Leinwandgemäldes aus dem
                    17. Jh. mit der Darstellung eines Karnevalsumzuges.
                    Umfangreiche Retusche von Fehlstellen. Im Auftrag von einer
                    Privatgalerie.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2008</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Friedhofskapelle Wondreb</h3>
                  <p className="referenzP">
                    Restaurierung der volkskundlich bedeutenden
                    Totentanzmalereien auf Holztafeln aus dem 18. Jh. sowie
                    Restaurierung des Altars und der Kunstausstattung. Im
                    Auftrag der kath. Kirchenverwaltung Wondreb.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2008</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Pfarrkirche St. Petrus in Sandsbach
                  </h3>
                  <p className="referenzP">
                    Befund und Rekonstruktion der Raumschalenfassung, von Martin
                    Bader (1730–1750) Restaurierung der Altäre um 1750 sowie
                    Skulpturen und Leinwandgemälde. Im Auftrag der kath.
                    Kirchenverwaltung Sandsbach.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2007</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Kirche Maria Himmelfahrt in Arth bei Landshut
                  </h3>
                  <p className="referenzP">
                    Altäre aus den 18. Jh. mit gotischen und barocken
                    Skulpturenbestand, u. a.Assistenzfiguren aus dem Umkreis von
                    Hans Leinberger. Holzkonservierung Festigung der Farbfassung
                    und Vergoldung, Kittung und Retusche. Im Auftrag der kath.
                    Kirchenverwaltung Arth.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2006</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Kath. Stadtpfarrkirche Hl. Dreifaltigkeit, Amberg
                  </h3>
                  <p className="referenzP">
                    Monumentales Holzrelief eines Gnadenstuhls von Andreas
                    Schwarzkopf (1935) und Seitenaltäre mit temperagebundenen
                    Gemälden auf Holztafeln, vorwiegend Substanzsicherung und
                    Reinigung sowie geringfügige Retuschen.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2005</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Kath. Filialkirche St. Jakobus, Lennesried (Lkr. Neustadt an
                    der Waldnaab)
                  </h3>
                  <p className="referenzP">
                    Hauptaltar von Johann Michael Doser von 1734,
                    Holzkonsolidierung, Gold- und Fassungsrestaurierung, sowie
                    Restaurierung der Leinwandgemälde. Im Auftrag der kath.
                    Kirchenverwaltung Waldthurn.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2003</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Veste Coburg, Fürstenbau, Kasimirzimmer, Esszimmer und Loge
                  </h3>
                  <p className="referenzP">
                    Arbeitsproben an der Holzvertäfelung, Konservierung und
                    Entfernung von Mikroorganismen. Im Auftrag des Staatlichen
                    Hochbauamtes und in Zusammenarbeit mit der Bayerischen
                    Verwaltung der staatlichen Schlösser Gärten und Seen.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2002</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Klosterkirche St Georg und Martin, Benediktinerabtei
                    Weltenburg bei Kelheim
                  </h3>
                  <p className="referenzP">
                    Behebung eines Wasserschadens an Leuchterkartuschen im
                    Chorraum, Behandlung gegen Mikroorganismen. Neuaufbau der
                    fehlenden Vergoldung, Erstellung einer Fotodokumentation und
                    eines Restaurierungsberichtes in der Datenbank FileMaker. Im
                    Auftrag des Staatlichen Hochbauamtes Landshut.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2001</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    St. Peter und Paul, Oberneuhausen
                  </h3>
                  <p className="referenzP">
                    Freilegung und Retusche der historischen Farbfassung von
                    1886 an den Altären und der Kanzel. Im Auftrag der kath.
                    Kirchenverwaltung Oberneuhausen.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2001</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Friedhofskirche St. Helena, Auerbach
                  </h3>
                  <p className="referenzP">
                    Hochaltar von 1721 und Seitenaltar von 1750, beide Michael
                    Doser zugeschrieben, Fassung von Michael Wild.
                    Konservierung, Retusche der Farbfassung und Vergoldung.
                    Zusammenarbeit mit Dipl.-Restaurator Sven Oehmig. Im Auftrag
                    der kath. Kirchenverwaltung Auerbach.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2000</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Stadtpfarrkirche St. Magdalena</h3>
                  <p className="referenzP">
                    Hochaltar von Bernhard Häusler von 1699/1700. Linker
                    Seitenaltar von 1760 mit Madonnenskulptur von 1420, rechter
                    Seitenalter von 1774 (Kopie nach Bernhard Kamm) mit der
                    Darstellung des Hl. Sebastian von 1780. Konservierung der
                    Vergoldung, Reinigung, Kittung und Retusche der Fehlstellen
                    am Altargehäuse und der Skulpturen. Im Auftrag von Erwin
                    Rösch.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2000</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Pfarrkirche St. Mauritius, Mintraching
                  </h3>
                  <p className="referenzP">
                    Restaurierung der Altäre und der beweglichen Ausstattung,
                    Konservierung der Vergoldung, Kittung und Retusche der
                    Fehlstellen. Im Auftrag von Kirchenverwaltung von
                    Mintraching.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2000</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Mariae Himmelfahrt, ehemalige Benediktinerabteikirche,
                    Frauenzell
                  </h3>
                  <p className="referenzP">
                    Grundsteinlegung 1737, Reinigung und Konservierung der
                    Raumschale. Reinigung und Konservierung der
                    Hochaltarskulpturen von Christian Jorhan d. Ä. (1727–1804).
                    Im Auftrag des Staatlichen Bauamtes Regensburg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2000</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Mariae Himmelfahrt, ehemalige Benediktinerabteikirche,
                    Frauenzell
                  </h3>
                  <p className="referenzP">
                    Reinigung und Konservierung der Hochaltarskulpturen von
                    Christian Jorhan (1727–1804). Im Auftrag des Staatlichen
                    Bauamtes Regensburg.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">2000</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Schloss Alteglofsheim bei Regensburg
                  </h3>
                  <p className="referenzP">
                    Restaurierung diverser Innenausstattung: Rekonstruktion der
                    Vergoldung an den Subraporten im Kaisersaal. Restaurierung
                    der Gemälderahmen im Asamsaal. Dort Behandlung des Befalls
                    von Mikroorganismen, Konservierung der Metallauflagen und
                    Fehlstellenretusche. Im Auftrag von Dipl. Restaurator
                    Michael Lange.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1999</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    St. Margaretha, Irfersdorf, Lkr. Eichstätt, Ölberggruppe um
                    1500
                  </h3>
                  <p className="referenzP">
                    Konservierung, Reinigung reduzierte Kreidegrundergänzung und
                    minimale Retusche an historischen Fassungen: Minimale
                    Ergänzung innerhalb der Inkarnate und der Farbfassung.
                    Bildhauerische Ergänzung fehlender Hände und Attribute von
                    Stefan Link und Neufassung von Astrid Mendes. Im Auftrag der
                    kath. Kirchenstiftung Irfersdorf.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1997</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Abtei St. Walburg, Eichstätt spätgotische Skulpturengruppe
                  </h3>
                  <p className="referenzP">
                    Schreinskulpturen des ehemaligen Flügelaltares um 1500 in
                    der Gruft von St. Walburg: Mikroskopische Untersuchung der
                    Farbfassungen. Farbliche Visualisierung der Fassungsphasen
                    von 1500, 1741 und 1870. Entfernung von Bronzierungen,
                    Konservierung der Farbfassung, Kreidegrundergänzung und
                    Strichretusche. Bildhauerische Ergänzung fehlender Hände und
                    Attribute wegen der Aufrechterhaltung der ikonografischen
                    Darstellung. Im Auftrag der Diözese Eichstätt.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1997 – 1998</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">Oberleierndorf, St. Stephanus</h3>
                  <p className="referenzP">
                    Filialkirche des Klosters Paring: Restaurierung der barocken
                    Ausstattung. Strukturelle Holzfestigung an Skulpturen.
                    Konservierung der Farbfassung und Vergoldung sowie Kittung
                    und Retusche. Im Auftrag der kath. Kirchenverwaltung
                    Oberleierndorf.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1995</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Klosterabtei Karthaus Prüll, Hochaltar von Johann Krump(p)er
                    (1570–1634) in Regensburg
                  </h3>
                  <p className="referenzP">
                    Festigung und Ergänzung, der mit Weizenkörnern belegten
                    Säulen am Hochaltar. Es handelt sich um die Imitation einer
                    Goldgranulattechnik, die mit Weizenkörnern simuliert wird.
                    Konservierung der Skulpturen. Mitarbeit im
                    Restaurierungsteam Thomas Schoeller.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1993</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Pfarrkirche zu den 12 Aposteln, Wunsiedel, Kreuzigungsgruppe
                    aus dem 19. Jh.
                  </h3>
                  <p className="referenzP">
                    Erstellung von Querschliffen im Labor der Hochschule für
                    Bildende Künste in Dresden. Analyse zur Entscheidungsfindung
                    für die Abnahme der Übermalungen. Rückführung auf die
                    Originalfassung und Retusche von Fehlstellen.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1991 – 1994</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Augustinerkirche, Mainz, Hochaltar im Typus eines
                    Baldachinaltars um 1773–1779
                  </h3>
                  <p className="referenzP">
                    Skulpturen vermutlich nach Entwürfen von Johann Peter Wagner
                    (1730–1809). Konservierung originaler Fassungsbefunde.
                    Wiederherstellung der Polierweißfassung an den
                    überlebensgroßen Assistenzskulpturen, Abraham und
                    Melchisedek sowie an der Kreuzabnahmegruppe, die dem Vorbild
                    Rubens folgte. Rekonstruktion fehlender Vergoldung und
                    Imitation von roten und rosafarbenen Farblasuren auf dem
                    Poliment der Vergoldungen nach historischem Vorbild und
                    Fundstücken. Erstellung von Arbeitsmustern an Dummies.
                    Begleitende Arbeitsprotokolle der Versuchsreihen und
                    Dokumentation der einzelnen Bereiche. Im Auftrag der
                    Erzdiözese Mainz.
                  </p>
                </div>
              </div>
              <div className="refDiv">
                <div className="jahreszahlDiv">1991</div>
                <div className="infoDiv">
                  <h3 className="referenzH3">
                    Hochschule für Bildende Künste Dresden, Studiengang
                    Kunsttechnologie
                  </h3>
                  <p className="referenzP">
                    Konservierung und Restaurierung von Kunst- und Kulturgut
                    unter Professor Sandner, Grundkurs Plastik geleitet von
                    Dipl.- Restauratorin Meier.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
